import React, { Suspense, useEffect, useState }  from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { renderRoutes, privateRenderRoutes } from "./config/routes";
import './i18n';
import "./common.scss";
import PrivateRoute from './PrivateRoute'
import Cookie from "js-cookie";
import { images } from "src/config/images";
import ReactLoading from 'react-loading';
import { CheckPrivileges } from './PermissionContexts.js';
const App = () => { 
  const urlParams = new URLSearchParams(window.location.search);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const clearSession = () => {
    localStorage.clear();
    Cookie.remove("is_socialLogin");
    Cookie.remove("laravel_session");
    Cookie.remove("trainocate_session");
    Cookie.remove("beta_block");
    Cookie.remove("userData");
    Cookie.remove("userData", { path: "/", domain: ".trainocate.com" });
  };

  return (
    !loading && (
      <div className="App">
           <Suspense fallback={<div style={
            {
              position: "absolute",
              top: "50%",
              left: "50%",}
           }>
             <ReactLoading type={'spin'} color={'#ff953f'} height={60} width={60} />
           </div>
          }>
          <BrowserRouter>
            <Switch>
              {renderRoutes.map(([key, route]) => (
                <Route
                  key={key}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
              <CheckPrivileges>
              {privateRenderRoutes.map(([key, route]) => (
                <PrivateRoute
                  key={key}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
              </CheckPrivileges>
            </Switch>
          </BrowserRouter>
        </Suspense>
      </div>
    )
  );
}

export default App;
